body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --color-background: white;
  --color-background-bg: #e6ecf0;
  --color-text: black;
  --color-link: blue;
  --color-used-link: #1da1f2;
  /* light grey divider */
  --color-divider: #e6ecf0;
}

[data-theme="dark"] {
  --color-background: #202123;
  --color-background-bg: #343541;
  --color-text: white;
  --color-link: lightblue;
  --color-used-link: #1da1f2;
  /* light grey divider */
  --color-divider: #343541;
}

/* a href */
a {
  color: var(--color-link);
  text-decoration: none;
}

hr {
  border: none;
  border-top: 2px solid var(--color-divider);
  margin: 20px 0;
}

@font-face {
  font-family: "Instrument Sans";
  src: local("Instrument Sans"),
    url(./fonts/InstrumentSans-VariableFont_wdth\,wght.ttf) format("truetype");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
